import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './App.scss';
import './scss/bootstrap/bootstrap.scss';
import '@fontsource-variable/jost';

import ReactGA from "react-ga4";
if (process.env.REACT_APP_GOOGLE_ANALYTICS_GA_MEASUREMENT_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_GA_MEASUREMENT_ID);

const About = lazy(() => import('./pages/About'));
const Index = lazy(() => import('./pages/Index'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Myservices = lazy(() => import('./pages/Myservices'));
const GetfreeQuote = lazy(() => import('./pages/GetfreeQuote'));
const Tools = lazy(() => import('./pages/Tools'));
const GradientGenerator = lazy(() => import('./pages/Tools/GradientGenerator'));
const BoxShadow = lazy(() => import('./pages/Tools/BoxShadow'));
const CSSFormatter = lazy(() => import('./pages/Tools/CSSFormatter'));

const App = () => (
  <BrowserRouter basename="/">
    <Suspense fallback={<Main />}>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/about-me" element={<About />} />
        <Route exact path="/my-portfolio" element={<Portfolio />} />
        <Route exact path="/get-free-quote" element={<GetfreeQuote />} />
        <Route exact path="/my-services" element={<Myservices />} />        
        <Route exact path="/tools" element={<Tools />} />
        <Route exact path="/tools/gradient-generator" element={<GradientGenerator />} />
        <Route exact path="/tools/box-shadow-generator" element={<BoxShadow />} />
        <Route exact path="/tools/css-formatter" element={<CSSFormatter />} />        
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
