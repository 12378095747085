import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../data/routes';
import { Container, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from "../../../src/images/logo.svg";

const Navigation = () => {
  const location = useLocation();
  const getActiveClass = (path) => {
    return location.pathname.startsWith(path) ? 'active px-2 px-xl-3 ' : 'px-2 px-xl-3';
  };
  const renderMenuItems = (items) => {
    return items
      .filter((item) => item.path !== '/')
      .map((item) => (
        <React.Fragment key={item.label}>
          <li className={getActiveClass(item.path)}>
            {item.hasDropdown ? (
              <NavDropdown title={item.label} id={item.label} >
                {item.children && renderSubMenuItems(item.children)}
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to={item.path} className='p-0'>
                {item.label}
              </Nav.Link>
            )}
          </li>
        </React.Fragment>
      ));
  };

  const renderSubMenuItems = (children) => {
    return children.map((child) => (
      <NavDropdown.Item as={Link} to={child.path} key={child.label} className={getActiveClass(child.path)}>
        {child.label}
      </NavDropdown.Item>
    ));
  };

  return (
    <header id="header" className='mb-5'>
      <Container>
        <Navbar expand="lg" className="py-3 row">
          <Col xs="5" lg="auto">
            {routes.filter((l) => l.index).map((l) => (
              <Link key={l.label} to={l.path}>
                <img src={Logo} alt="Vishal Bavadiya" height={54} width={89} />
              </Link>
            ))}
          </Col>
          <Col className='text-end text-xl-center p-0'>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
              <Nav className="justify-content-end">
                <ul>
                  {renderMenuItems(routes)}
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col xs="5" lg="auto" className='text-end d-none d-md-block'>
            <Link className='btn btn-outline-secondary' size="xs-sm" to="https://www.upwork.com/freelancers/bavadiyavishal" target="_blank">Hire Me</Link>
          </Col>
        </Navbar>
      </Container>
    </header>
  );
};

export default Navigation;
