const routes = [
  {
    index: true,
    label: "",
    path: '/',
  },
  {
    label: 'About Me',
    path: '/about-me',
  },
  {
    label: 'My Services',
    path: '/my-services',
  },
  {
    label: 'My Portfolio',
    path: '/my-portfolio',
  },
  {
    label: 'Tools',
    path: '/tools',
    hasDropdown: 'true',
    children: [
      {
        label: 'Gradient Generator',
        path: '/tools/gradient-generator',
      },
      {
        label: 'Shadow Generator',
        path: '/tools/box-shadow-generator',
      },
      {
        label: 'CSS Formatter',
        path: '/tools/css-formatter',
      }
    ],

  },
  {
    label: 'Blog',
    path: 'https://blog.mevishal.com/',
  },
];

export default routes;
